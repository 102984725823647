import { useInterval } from '@/composables/useInterval';
import { asyncComputed } from '@/utils/signals/asyncComputed';
import { loader } from '@/utils/signals/loader';
import { Tracked } from '@/utils/signals/tracked';
import { HttpClient } from '@angular/common/http';
import { Component, computed, inject, Input, signal } from '@angular/core';
import { BuildingScanService } from '@artemis-software/wr-api';
import { firstValueFrom } from 'rxjs';
import { PendingChanges } from '@guards/pending-changes.guard';

@Component({
  selector: 'wr-building-scan-list',
  templateUrl: './building-scan-list.component.html',
  styleUrls: ['./building-scan-list.component.scss'],
})
export class BuildingScanListComponent implements PendingChanges {
  @Input()
  @Tracked()
  buildingId!: string;

  readonly service = inject(BuildingScanService);

  readonly httpClient = inject(HttpClient);

  constructor() {
    useInterval(() => this.reload(), 10_000);
  }

  hasPendingChanges(): boolean | Promise<boolean> {
    return this.uploading();
  }

  readonly loading = loader();

  readonly scans = asyncComputed(
    () => (this.buildingId ? this.service.getBuildingScans(this.buildingId) : []),
    [],
    this.loading,
  );

  readonly pendingScans = asyncComputed(
    () => (this.buildingId ? this.service.getImportStatus(this.buildingId) : []),
    [],
    this.loading,
  );

  readonly empty = computed(() => this.scans().length === 0 && this.pendingScans().length === 0);

  reload() {
    this.scans.reload();
    this.pendingScans.reload();
  }

  onFileChange(event: Event) {
    const file = (event.target as HTMLInputElement).files?.[0];

    if (file) {
      this.uploadScan(file);
    }
  }

  progress = signal(0);

  uploading = signal(false);

  async uploadScan(file: File) {
    try {
      this.uploading.set(true);

      const { id, uploadUrl } = await firstValueFrom(this.service.uploadFile(this.buildingId, 'Panorama'));

      await new Promise<void>((resolve, reject) => {
        const xhr = new XMLHttpRequest();

        xhr.upload.addEventListener('progress', (event) => {
          if (event.lengthComputable) {
            this.progress.set(event.loaded / event.total);
          }
        });

        xhr.addEventListener('readystatechange', () => {
          if (xhr.readyState === 4) {
            if (xhr.status === 200) {
              resolve();
            } else {
              reject();
            }
          }
        });

        xhr.open('PUT', uploadUrl);

        xhr.setRequestHeader('Content-Type', file.type);

        xhr.send(file);
      });

      await firstValueFrom(this.service.triggerImport(id));

      this.reload();
    } finally {
      this.uploading.set(false);
    }
  }
}
