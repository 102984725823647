import { Component, inject, Input } from '@angular/core';
import { BuildingEquipmentTreeViewDto, PointOfInterestService } from '@artemis-software/wr-api';
import { Tracked } from '@/utils/signals/tracked';
import { ScanViewerService } from '@pages/building-scan-detail-page/scan-viewer-service';

@Component({
  selector: 'wr-building-scan-equipment-tree-view',
  templateUrl: './building-scan-equipment-tree-view.component.html',
  styleUrls: ['./building-scan-equipment-tree-view.component.scss'],
})
export class BuildingScanEquipmentTreeViewComponent {
  readonly trackByFn = (index: number, item: BuildingEquipmentTreeViewDto) => item.id;

  private readonly scanViewerService = inject(ScanViewerService);

  get buildingEquipments() {
    return this.scanViewerService.buildingEquipments();
  }

  get loading() {
    return this.scanViewerService.buildingEquipmentsLoading();
  }

}
