<div [nbSpinner]="loading">
  <div class="poi" *ngFor="let entry of pointsOfInterest" (click)="jumpToPointOfInterest(entry)">
    <nb-icon pack="eva" [icon]="getIcon(entry)"></nb-icon>
    <div class="poi-name">
      {{ entry.name }}
    </div>
  </div>
</div>
<div class="center-button" *ngIf="!loading && !isCreatingPointOfInterest()" (click)="beginCreatingPointOfInterest()">
  <button nbButton status="primary">POI hinzufügen</button>
</div>

<form class="poi" *ngIf="creatingPointOfInterest() as poi" (submit)="finishCreatingPointOfInterest(poi)">
  <nb-icon pack="eva" icon="pin-outline"></nb-icon>
  <input #input nbInput type="text" name="name" [(ngModel)]="poi.name" placeholder="Point of Interest">
  <button nbButton type="submit" status="primary">Speichern</button>
</form>
