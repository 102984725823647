import { Component, ElementRef, inject, Input, signal, ViewChild } from '@angular/core';
import { PointOfInterestDto } from '@artemis-software/wr-api';
import { Tracked } from '@/utils/signals/tracked';
import { CreatePointOfInterestOptions, ScanViewerService } from '@pages/building-scan-detail-page/scan-viewer-service';

@Component({
  selector: 'wr-building-scan-poi-list',
  templateUrl: './building-scan-poi-list.component.html',
  styleUrls: ['./building-scan-poi-list.component.scss'],
})
export class BuildingScanPoiListComponent {
  @ViewChild('input') input?: ElementRef<HTMLInputElement>;

  private readonly scanViewerService = inject(ScanViewerService);

  jumpToPointOfInterest(poi: PointOfInterestDto) {
    this.scanViewerService.jumpToPointOfInterest(poi);
  }

  get pointsOfInterest() {
    return this.scanViewerService.pointsOfInterest();
  }

  get loading() {
    return this.scanViewerService.pointsOfInterestsLoading();
  }

  getIcon(pointOfInterest: PointOfInterestDto) {
    if (pointOfInterest.buildingEquipmentId)
      return 'cube-outline';
    if (pointOfInterest.maintenanceTimerId)
      return 'clock-outline';
    return 'pin-outline';
  }


  readonly isCreatingPointOfInterest = signal(false);

  readonly creatingPointOfInterest = signal<CreatePointOfInterestOptions | null>(null);

  async beginCreatingPointOfInterest() {
    this.isCreatingPointOfInterest.set(true);
    const result = await this.scanViewerService.pickPosition();
    if (!result) {
      this.isCreatingPointOfInterest.set(false);
      return;
    }

    this.creatingPointOfInterest.set({
      name: 'Point of Interest',
      position: result.point,
      panoramaId: result.panorama.id,
    });

    setTimeout(() => {
      this.input?.nativeElement.focus();
      this.input?.nativeElement.select();
    });


  }

  async finishCreatingPointOfInterest(poi: CreatePointOfInterestOptions) {
    poi.name = poi.name.trim();

    if (poi.name.length === 0)
      poi.name = 'Point of Interest';

    try {
      await this.scanViewerService.createPointOfInterest(poi);
    } finally {
      this.isCreatingPointOfInterest.set(false);
      this.creatingPointOfInterest.set(null);
    }
  }
}
